import featureTogglesService from '@/shared/services/featureTogglesService/featureTogglesService';
import notify from '@/shared/helpers/notify/notify';
import i18n from '@/plugins/i18n/i18n';
import { Feature, HttpErrorCodes } from '@/shared/types/generic';
import { ref } from 'vue';
import { ResponseError } from '@/shared/types/utils';
import axios from 'axios';

const featureList = ref<Feature[]>([]);

export default function useFeatures() {
  const loadFeatures = async (): Promise<void> => {
    try {
      featureList.value.push(
        ...((await featureTogglesService.getFeatureToggles())?.features ?? []),
      );
    } catch (e) {
      if (
        (e instanceof ResponseError &&
          (e.status === HttpErrorCodes.LOCKED_USER ||
            e.status === HttpErrorCodes.LOCKED)) ||
        (axios.isAxiosError(e) &&
          (e.response?.status === HttpErrorCodes.LOCKED_USER ||
            e.response?.status === HttpErrorCodes.LOCKED))
      ) {
        throw e;
      } else {
        notify(i18n.global.t('errors.general.wrong'), 'danger');
      }
    }
  };

  const isFeatureEnabled = (feature: Feature): boolean => {
    return featureList.value.includes(feature);
  };

  const $reset = () => {
    featureList.value = [];
  };

  return { loadFeatures, isFeatureEnabled, $reset };
}
