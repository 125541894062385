import { Component } from 'vue';
import { PermissionCode } from './roles';

export interface ResponseMetadata {
  totalItems?: number;
  lastUpdated?: number;
}

export interface ApiResponse<T> {
  metadata: ResponseMetadata;
  results: T;
}

export interface IBase {
  reference: string;
}

export interface AppButtonToggleOption {
  label?: string;
  value: string | number;
  slot?: string;
  disabled?: boolean;
  tooltip?: string;
}

export interface SkeletonStyle {
  type:
    | 'QAvatar'
    | 'QBadge'
    | 'QBtn'
    | 'QCheckbox'
    | 'QChip'
    | 'QInput'
    | 'QRadio'
    | 'QRange'
    | 'QSlider'
    | 'QToggle'
    | 'QToolbar'
    | 'circle'
    | 'rect'
    | 'text';
  style: string;
}

export type AppLabelSize = 'medium' | 'large';

export interface Range<T> {
  from: T;
  to: T;
}

export enum ImageTypeStaticReference {
  COVER = 'COVER',
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM',
  TEAM = 'TEAM',
}

export enum ReactionType {
  BAD = 'bad',
  SAD = 'sad',
  NORMAL = 'normal',
  GOOD = 'good',
  HAPPY = 'happy',
}

export interface Reaction {
  type: ReactionType;
  value: number;
  score: number;
}

export interface Image {
  name: string;
  url: string;
  description?: string;
  organizationId?: number;
  imageType?: ImageTypeStaticReference;
  default?: boolean;
  reference: string;
}

export enum FileType {
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
}

export type NuvoImportActionLevel = 'discard' | 'submit' | 'block';

export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
  DE = 'de-DE',
  ES = 'es-ES',
}

export enum FeatureVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum HttpErrorCodes {
  TEST = -1,
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
  LOCKED = 701,
  WIZARD_USER_INVALID = 2523,
  WIZARD_TEAM_INVALID = 2518,
  WIZARD_METRIC_INVALID = 226,
  WIZARD_START_DATE_INVALID = 2501,
  ORGANIZATION_HAS_NO_USERS = 1024,
  USER_IS_NO_LONGER_VALID = 2523,
  TEAM_IS_NO_LONGER_VALID = 2518,
  NO_USERS_FOUND = 1024,
  NO_METRICS_IN_ORG = 6032,
  METRIC_NOT_FOUND = 226,
  TEAM_INVALID_NUMBER_USERS = 2527,
  PERMISSIONS_UPDATED = 701,
  PUBLIC_DISPLAYS_AUDIENCE_NOT_ELIGIBLE = 273,
  PUBLIC_DISPLAYS_NO_WORKERS = 272,
  PUBLIC_DISPLAYS_NO_WIDGET_SELECTED = 276,
  PUBLIC_DISPLAYS_METRIC_NOT_ELIGIBLE = 277,
  PUBLIC_DISPLAYS_NO_METRICS = 278,
  PUBLIC_DISPLAYS_EMPTY_METRICS = 280,
  LOCKED_USER = 423,
  LOCKED_OTP = 13001,
}

export enum RouteAuthenticationRequirement {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNIVERSAL,
}

export enum Feature {
  METRIC_PERFORMANCE_DASHBOARD = 'METRIC_PERFORMANCE_DASHBOARD',
  ERROR_HANDLING = 'ERROR_HANDLING',
  PUBLIC_DISPLAY_MANAGER = 'PUBLIC_DISPLAY_MANAGER',
  DUPLICATE_CHALLENGE_MANAGER = 'DUPLICATE_CHALLENGE_MANAGER',
  SURVEY_CUSTOM_QUESTIONS = 'SURVEY_CUSTOM_QUESTIONS',
  SURVEY_DEFAULT_QUESTIONS = 'SURVEY_DEFAULT_QUESTIONS',
  DASHBOARD_MANAGER = 'DASHBOARD_MANAGER',
  FTX = 'FTX',
  KUDOS = 'KUDOS',
  METRIC_DATA_IMPORT = 'METRIC_DATA_IMPORT',
  MANAGER_SETTINGS = 'MANAGER_SETTINGS',
  PUBLIC_DISPLAY_PIN = 'PUBLIC_DISPLAY_PIN',
  METRIC_OVERVIEW_VISIBILITY = 'METRIC_OVERVIEW_VISIBILITY',
  PUBLIC_DISPLAY_LIST = 'PUBLIC_DISPLAY_LIST',
}

export enum QuasarLang {
  PT = 'pt',
  EN_US = 'en-US',
  DE_DE = 'de-DE',
  ES_ES = 'es',
}

export enum DurationPreset {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  OTHER = 'other',
}

export interface DurationModel {
  startDate: Date;
  endDate: Date;
  preset: DurationPreset;
}

export interface DurationErrorModel {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export enum DatePeriod {
  ZERO = 0,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
  QUARTER = 7776000000,
  YEAR = 31104000000,
}

export enum ProgressBarColor {
  PRIMARY = '#2db383',
  SECONDARY = '#BEE7D9',
}

export interface Status {
  name: string;
  translationKey: string;
  reference: string;
}

export enum StatusType {
  METRIC = 'METRIC',
}

export interface SelectOption {
  label: string;
  reference: string;
}
export interface NavigationLink {
  to: string;
  icon: string | Component;
  testId: string;
  labelKey: string;
  id?: string;
  feature?: Feature;
  permissions?: PermissionCode[];
}

export interface StepsButtonsLabel {
  next: string;
  last: string;
}

export interface SelectGroupItem {
  name: string;
  value: string;
  valueDescription?: string;
}

export interface SelectGroup {
  groupLabel: string;
  groupAppendLabel?: string;
  value: string;
  items?: SelectGroupItem[];
}

export interface SelectMode {
  audience: string;
  disable: boolean;
  items: SelectGroupItem[];
}

export interface SelectBase {
  label: string;
  disable: boolean;
  items: SelectGroupItem[];
}

export enum SidebarTitle {
  CHALLENGE = 0,
  USER,
  METRIC,
  ACTIVITY,
  ITEM,
  SURVEY,
  PUBLIC_DISPLAYS,
}

export interface ProcessCompletionDetails {
  motive: string;
  createdAt: number;
  isAutomatic: boolean;
}

export enum Routes {
  ROOT = '/',
  ICONS = '/icons',
  HOME = '/home',
  LOGIN = '/login',
  FIRST_LOGIN = '/first-login',
  FAREWELL = '/farewell',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password',
  USERS = '/users',
  USERS_TEAMS = '/users/teams',
  USERS_ADD = '/users/add',
  USERS_MANAGERS = '/users/managers',
  LEADERBOARD = '/leaderboard',
  METRICS = '/metrics',
  METRICS_OVERVIEW = '/metrics/overview',
  METRICS_TABLE = '/metrics/all',
  METRICS_CATEGORIES = '/metrics/categories',
  METRICS_ADD = '/metrics/add',
  ACCOUNT_SETTINGS = '/account-settings',
  ACCOUNT_SETTINGS_EDIT_PROFILE = '/account-settings/edit-profile',
  ACCOUNT_SETTINGS_SECURITY = '/account-settings/security',
  ACCOUNT_SETTINGS_SECURITY_CHANGE_PASSWORD = '/account-settings/security/change-password',
  ACCOUNT_SETTINGS_SECURITY_DELETE_ACCOUNT = '/account-settings/security/delete-account',
  SETTINGS = '/settings',
  SETTINGS_SITES = '/settings/sites',
  SETTINGS_ROLE_LEVELS = '/settings/role-levels',
  CHALLENGES = '/challenges',
  CHALLENGES_ADD = '/challenges/add',
  STORE = '/store',
  STORE_PRIZES = '/store/prizes',
  STORE_COLLECTABLES = '/store/collectables',
  STORE_PURCHASE_ACTIVITY = '/store/purchase-activity',
  STORE_ADD = '/store/add',
  DATA_IMPORT = '/data',
  DATA_IMPORT_ADD = '/data/add',
  PUBLIC_DISPLAYS = '/public-displays',
  SURVEYS = '/surveys',
  SURVEYS_ADD = '/surveys/add',
  SURVEYS_ADD_ID = '/surveys/add/id',
  SURVEYS_ADD_DURATION = '/surveys/add/duration',
  SURVEYS_ADD_QUESTIONS = '/surveys/add/questions',
  SURVEYS_ADD_SUMMARY = '/surveys/add/summary',
  ERROR_502 = '/502',
  ERROR_503 = '/503',
  ERROR_404 = '/404',
  PUBLIC_DISPLAYS_ADD = '/public-displays/add',
}

export enum BackendErrorCodes {
  ROLE_LEVEL_NAME_ALREADY_EXISTS = 80002,
}

export enum AllStaticReference {
  ALL = 'all',
}

export interface LockedResponse {
  lockedOutEndTime: number;
  userTimezone: string;
}

export interface OTPLockedResponse {
  code: number;
  lockedOutEndTime: number;
  message: string;
  userTimezone: string;
}
