import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import qs from 'qs';
import {
  Category,
  CreateMetricRequestBody,
  EditMetric,
  Metric,
  CategorizedMetrics,
  ChartMetricsResponse,
  LastSearchMetricsResponse,
  CreateCategoryRequestParameters,
  EditCategoryRequestBody,
  GetChartPointsRequestBody,
  UnitOfMeasure,
  MetricCategoryType,
  SimplifiedCategory,
  LoadMetricRequest,
  LoadMetricsResponse,
  MetricsCount,
  CalculationMethod,
} from '@/shared/types/metrics';
import { GetRequestParameters } from '@/shared/types/requests';

export default {
  getMetrics(
    request: LoadMetricRequest,
  ): Promise<ApiResponse<LoadMetricsResponse>> {
    return axios.get('/metrics', {
      params: {
        page: request.page,
        size: request.size,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        statuses: request.statuses,
        categories: request.categories,
        noCategories: request.noCategories,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  getCounters(): Promise<MetricsCount> {
    return axios.get('/metrics/count');
  },

  removeMetric(reference: string) {
    return axios.delete(`/metrics/${reference}`);
  },

  removeCategory(reference: string) {
    return axios.delete(`/categories/${reference}`);
  },

  validateMetric(reference: string): Promise<{ message: string }> {
    return axios.get(`/metrics/${reference}/data-associated/`);
  },

  getCategories({
    page,
    size,
    sortBy,
    orderBy,
    queryBy,
  }: GetRequestParameters = {}): Promise<
    ApiResponse<{ categories: Category[] }>
  > {
    return axios.get('/categories', {
      params: {
        page,
        size,
        sort_by: sortBy,
        order_by: orderBy,
        query_by: queryBy,
      },
    });
  },

  getUnitOfMeasure(
    categoryReference?: string,
    metricsReferences?: string[],
  ): Promise<ApiResponse<{ unitsMeasure: UnitOfMeasure[] }>> {
    return axios.get('/units-measure', {
      params: { categoryReference, metricsReferences },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },

  editMetric(
    metric: EditMetric,
    metricReference: string,
  ): Promise<{ message: string }> {
    return axios.patch(`/metrics/${metricReference}`, metric);
  },

  createMetric(requestBody: CreateMetricRequestBody): Promise<void> {
    return axios.post('/metrics', requestBody);
  },

  createCategory(newCategory: CreateCategoryRequestParameters): Promise<void> {
    return axios.post('/categories', newCategory);
  },

  getCategorizedMetrics(
    isUsedInChallenge?: boolean,
    isActiveMetric?: boolean,
    isSafetyFeature?: boolean,
    audienceStaticReference?: string,
    isMultipleSites?: boolean,
    challengeModeStaticReference?: string,
    challengeTypeStaticReference?: string,
  ): Promise<CategorizedMetrics> {
    return axios.get(`/metrics/category`, {
      params: {
        isUsedInChallenge,
        isActiveMetric,
        isSafetyFeature,
        audienceStaticReference,
        isMultipleSites,
        challengeModeStaticReference,
        challengeTypeStaticReference,
      },
    });
  },

  getLastSearch(): Promise<LastSearchMetricsResponse> {
    return axios.get('/charts/metrics/last-search');
  },

  editCategory(
    reference: Category['reference'],
    requestBody: EditCategoryRequestBody,
  ): Promise<void> {
    return axios.put(`/categories/${reference}`, requestBody);
  },

  associateMetricsToCategory(
    metricReferences: Metric['reference'][],
    categoryReference?: Category['reference'],
  ): Promise<void> {
    return axios.patch('/metrics/category', {
      metricReferences,
      categoryReference,
    });
  },

  getMetricTypes(): Promise<
    ApiResponse<{ metricTypes: MetricCategoryType[] }>
  > {
    return axios.get('/metric-types');
  },

  getChartPoints(
    requestBody: GetChartPointsRequestBody,
  ): Promise<ChartMetricsResponse> {
    return axios.post('/charts/metrics', requestBody);
  },

  getSimplifiedCategories(
    isSafetyFeature?: boolean,
    categoryTypeReference?: string,
  ): Promise<ApiResponse<{ categories: SimplifiedCategory[] }>> {
    return axios.get('/categories/simplified', {
      params: { isSafetyFeature, categoryTypeReference },
    });
  },

  getCategoryTypes(): Promise<
    ApiResponse<{ categoryTypes: MetricCategoryType[] }>
  > {
    return axios.get('/category-types');
  },

  getCalculationMethods(
    categoryReference?: string,
    metricReference?: string,
    challengeModeStaticReference?: string,
    challengeTypeStaticReference?: string,
  ): Promise<ApiResponse<{ calculationTypes: CalculationMethod[] }>> {
    return axios.get('/calculation-types', {
      params: {
        categoryReference,
        metricReference,
        challengeModeStaticReference,
        challengeTypeStaticReference,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
};
