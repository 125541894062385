<template>
  <div class="app-duration" data-testid="app-duration">
    <AppButtonToggle
      v-model="model.preset"
      :class="[
        'app-duration__buttons',
        { 'app-duration__buttons--fill-width': fillWidth },
      ]"
      data-testid="app-duration-btn"
      :options="presetOptions"
      isolated-buttons
    />
    <AppSequence>
      <div>
        <p
          class="app-duration__title"
          data-testid="app-duration-startDate-label"
        >
          {{ $t('common.startDate') }}
        </p>
        <div
          class="app-duration__dates"
          data-testid="app-duration-startDate-container"
        >
          <AppDatePicker
            v-model="model.startDate"
            class="app-duration__start-time"
            :error="errors.startDate"
            data-testid="app-duration-startDate"
          />
          <AppTimePicker
            v-model="model.startDate"
            v-model:error="modelError.startTime"
            class="app-duration__start-time"
            align-icon="prepend"
            data-testid="app-duration-startTime"
          />
        </div>
      </div>
      <div>
        <p class="app-duration__title" data-testid="app-duration-endDate-label">
          {{ $t('common.endDate') }}
        </p>
        <div
          class="app-duration__dates"
          data-testid="app-duration-EndDate-container"
        >
          <AppDatePicker
            v-model="model.endDate"
            class="app-duration__end-time"
            :disabled="disableEndDate"
            :error="errors.endDate"
            data-testid="app-duration-endDate"
          />
          <AppTimePicker
            v-model="model.endDate"
            v-model:error="modelError.endTime"
            class="app-duration__end-time"
            align-icon="prepend"
            data-testid="app-duration-endTime"
          />
        </div>
        <div class="app-duration_warnings">
          <div class="app-duration-result-forecast">
            <AppResultsRelease
              v-if="!!resultsForecast"
              :date="resultsForecast"
              :context="context"
            />
          </div>
          <AppMessage
            v-if="showWarning"
            class="warning"
            :icon="outlinedInfo"
            data-testid="warning-message"
          >
            <p class="app-duration__warning" data-testid="app-duration-warning">
              <span
                class="app-duration__warning-title"
                data-testid="app-duration-warning-title"
              >
                {{ $t('common.reminder') }}
              </span>
              {{ warning }}
            </p>
          </AppMessage>
          <AppMessage
            v-if="teamsWarning"
            class="warning"
            :icon="outlinedInfo"
            data-testid="warning-message"
          >
            <p class="app-duration__warning" data-testid="app-duration-warning">
              <span
                class="app-duration__warning-title"
                data-testid="app-duration-warning-title"
              >
                {{ $t('common.reminder') }}
              </span>
              {{ teamsWarning }}
            </p>
          </AppMessage>
        </div>
      </div>
    </AppSequence>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapStores } from 'pinia';
import AppSequence from '@/components/app/AppSequence/AppSequence.vue';
import AppDatePicker from '@/components/app/AppDatePicker/AppDatePicker.vue';
import AppTimePicker from '@/components/app/AppTimePicker/AppTimePicker.vue';
import AppMessage from '@/components/app/AppMessage/AppMessage.vue';
import {
  AppButtonToggleOption,
  DurationPreset,
  DatePeriod,
  DurationModel,
  DurationErrorModel,
} from '@/shared/types/generic';
import AppButtonToggle from '@/components/app/AppButtonToggle/AppButtonToggle.vue';
import { outlinedInfo } from '@quasar/extras/material-icons-outlined';
import { matInfo } from '@quasar/extras/material-icons';
import useProfileStore from '@/store/profile/useProfileStore';
import { ResultsContext } from '@/shared/types/challenges';
import AppResultsRelease from '../AppResultsRelease/AppResultsRelease.vue';

export default defineComponent({
  name: 'AppDuration',

  components: {
    AppSequence,
    AppDatePicker,
    AppTimePicker,
    AppButtonToggle,
    AppMessage,
    AppResultsRelease,
  },

  props: {
    modelValue: {
      type: Object as PropType<DurationModel>,
      required: true,
    },
    warning: {
      type: String,
      default: '',
    },
    teamsWarning: {
      type: String,
      default: '',
    },
    resultsForecast: {
      type: Number,
      default: 0,
    },
    context: {
      type: String as PropType<ResultsContext>,
      default: '',
    },
    errors: {
      type: Object as PropType<DurationErrorModel>,
      required: true,
    },
    fillWidth: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'update:errors'],

  data() {
    return {
      presetOptions: [
        DurationPreset.DAY,
        DurationPreset.WEEK,
        DurationPreset.MONTH,
        DurationPreset.OTHER,
      ].map((duration) => ({
        value: duration,
        label: this.$t(`enums.duration.${duration}`),
      })) as AppButtonToggleOption[],
      outlinedInfo,
      matInfo,
    };
  },

  computed: {
    ...mapStores(useProfileStore),

    model: {
      get(): DurationModel {
        return this.modelValue;
      },

      set(newValue: DurationModel): void {
        this.$emit('update:modelValue', newValue);
      },
    },

    showTooltipResultForecastNormal(): string {
      return this.context === ResultsContext.CHALLENGE
        ? this.$t('timezones.tooltip.challengeResultForecast')
        : this.$t('timezones.tooltip.surveyResultForecast');
    },

    showTooltipResultForecastBold(): string {
      return this.context === ResultsContext.CHALLENGE
        ? this.$t('timezones.tooltip.challengeResultForecastBold')
        : this.$t('timezones.tooltip.surveyResultForecastBold');
    },

    modelError: {
      get(): DurationErrorModel {
        return this.errors;
      },

      set(newValue: DurationErrorModel): void {
        this.$emit('update:errors', newValue);
      },
    },

    disableEndDate(): boolean {
      return this.model.preset !== DurationPreset.OTHER;
    },

    showWarning(): boolean {
      return !!this.warning;
    },
  },

  watch: {
    'model.preset': {
      handler(newPreset: DurationPreset, oldPreset: DurationPreset): void {
        if (newPreset !== oldPreset && oldPreset) {
          this.updateDates(true);
        }
      },
    },

    'model.startDate': {
      handler(newStartDate: Date, oldStartDate: Date): void {
        if (newStartDate !== oldStartDate && oldStartDate) {
          this.updateDates(false);
        }
      },
    },
  },

  created(): void {
    if (!this.model.preset) {
      this.model.preset = DurationPreset.DAY;
      this.updateDates(true);
    }
  },

  methods: {
    updateDates(resetStartDate: boolean): void {
      switch (this.model.preset) {
        case DurationPreset.DAY:
          if (resetStartDate) this.setStartDate();
          this.applyPreset(DatePeriod.ZERO);
          break;
        case DurationPreset.WEEK:
          if (resetStartDate) this.setStartDate();
          this.applyPreset(DatePeriod.WEEK);
          break;
        case DurationPreset.MONTH:
          if (resetStartDate) this.setStartDate();
          this.applyPreset(DatePeriod.MONTH);
          break;
        default:
          break;
      }
    },

    applyPreset(daysAfterStart: DatePeriod): void {
      const { startDate } = this.modelValue;

      const endDateStamp = daysAfterStart + DatePeriod.DAY;

      this.model.endDate = new Date(startDate.getTime() + endDateStamp);
    },

    setStartDate(): void {
      this.model.startDate = new Date(
        Date.now() +
          (this.profileStore.userProfile?.userConfig.enrollmentTime || 0) +
          DatePeriod.MINUTE * 5,
      );
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.app-sequence) {
  margin-top: 41px;
}

.app-duration__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: $gray-800;
  margin-bottom: 8px;
}

.app-duration__dates {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.app-duration__warning {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $gray-800;
  margin-bottom: 0;
}

.app-duration__warning-title {
  font-weight: 700;
}

.app-duration_warnings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-duration-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.app-duartion-result-forecast__bold {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: $gray-700;
}

.warning.app-message.app-message--info {
  width: 100%;
}

.app-duration-result-forecast {
  display: flex;
  flex-direction: row;
}

.app-duration-result-forecast :deep(.app-message.app-message--empty) {
  align-items: center;
}

:deep(.app-error-message__text) {
  white-space: normal;
}

:deep(.q-field--with-bottom) {
  padding-bottom: 40px;
}

:deep(.app-sequence > *:not(:last-child)) {
  padding-bottom: 0;
}

.app-duration__buttons :deep(.q-btn) {
  min-width: 112px;
}

.app-duration__buttons--fill-width :deep(.q-btn) {
  width: 100%;
  min-width: unset;
}
</style>
